$white: white;
$blue: #0068bd;
$yellow: #ffc20e;
$dark-grey: #222222;

// Louise's gray palette
$grey-1: #f0f0f0;
$grey-2: #efefef;
$grey-3: #e8e8e8;
$grey-4: #999999;
$grey-5: #666666;
$grey-6: #4d4d4d;
$grey-7: #1d2124;
$grey-8: #141414;

$refresh-button-size: 1.2rem;
$refresh-icon-size: 0.75rem;
$sidebarWidth: 13.75rem;
$sidebarWidthMobile: 2.5rem;
$saveCancelBlockHeight: 5rem;
$headerHeight: 3.5625rem;
$contentFooterHeight: 3rem;

$optionSelectorTopLabelHeigth: 1.3rem;
$optionSelectorButtonRowHeight: 2.5rem;
$optionSelectorMenuHeight: 10rem;
$optionSelectorSearchHeight: 2rem;
