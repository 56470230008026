@import "../../variables";
@import "../../../node_modules/bootstrap/scss/bootstrap";

.main-content,
.modal-content {
  font-size: 0.875rem;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.text-underlined {
  text-decoration: underline;
}

p {
  white-space: pre-line;
}
