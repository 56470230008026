.meter-link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.alert .error-icon {
  font-size: 1rem;
}
