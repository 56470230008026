@import "../../app/App";
@import "../../../variables.scss";

.border-bottom-2px {
  border-bottom-width: 2px !important;
}
.SaveCancelContainer {
  border-top: 1px solid lightgrey;
  left: $sidebarWidthMobile !important;

  @media (min-width: 576px) {
    left: $sidebarWidth !important;
  }
}
.editButton {
  right: 0;
}

.edit-configuration-settings-block {
  margin-bottom: $saveCancelBlockHeight;
}
