@import "../../app/App";
.top-border-yellow {
  border-top-color: $warning !important;
  border-top-width: 3px !important;
}
.pointy {
  cursor: pointer;
}

.alert .details-button {
  font-size: 0.875rem;
  border: none;
  display: inline;
  text-decoration-line: underline;
  position: absolute;
  right: 5px;
  top: 6px;
}

.alert .times-button {
  font-size: 0.75rem;
  position: absolute;
  right: 0px;
  top: 0px;
}

.alert .result-icon {
  font-size: 1rem;
}
