button.edit-screen-btn {
    float: right;
    font-size: 0.875rem;
    padding: 0.1875rem 0.75rem;
    margin: 0.25rem;
}

button.edit-field-btn {
    font-size: 0.75rem;
    margin-right: 0.5rem;
    padding: 0;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.add-field-btn {
    font-size: 0.75rem;
    margin: 0.25rem 0.5rem;
    padding: 0.1875rem 1rem;
}

.edit-btn-caption {
    margin-left: 0.75rem;
}

.screen-section {
    background-color: #e9ecef;
    margin: 0.25rem 0.25rem 0.5rem 0.25rem;
    padding-bottom: 0.25rem;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section-name {
    line-height: 2rem;
    margin: 0.25rem;
    padding-left: 0.5rem;
}

.field-view {
    background-color: white;
    margin: 0.25rem;
    margin-top: 0;
    height: 2.5rem;
    display: flex;
    align-items: center;
}

.field-view:last-child {
    margin-bottom: 0;
}

.field-name {
    line-height: 2.5rem;
    padding: 0rem 1rem;
    margin-right: auto;
}

.field-attribute {
    border: solid;
    border-radius: 0.25rem;
    border-width: 1px;
    padding: 0.25rem;
    margin-right: 0.5rem;
    height: 2rem;
    line-height: 2rem;
    padding: 0 0.5rem;
    white-space: nowrap;
}

.attribute-label {
    padding-left: 0.25rem;
    margin: 0;
    cursor: pointer;
    user-select: none;
}

.attribute-checkbox {
    cursor: pointer;
    position: relative;
    vertical-align: middle;
    bottom: 0.09375rem;;
}

.attribute-checkbox-selected {
    box-shadow: 0px 0px 0px 1px white;
}

.attribute-on {
    border-color: blue;
    background-color: #0068bd;
    color: white;
}

.attribute-off {
    border-color: lightgrey;
    background-color: #e9ecef;
}

.attribute-disabled {
    background-color: lightgrey;
}

.nav-item .configuration-tab {
    padding: 0.25rem 0rem;
    margin: 0.5rem 1rem;
}

.configuration-tab-active {
    border-bottom: solid 3px #ffc20e;
    font-weight: bold;
}

.main-screen-editor {
    display: flex;
    flex-flow: column;
    overflow: auto;
    background-color: #e9ecef;
    padding: 1rem;
    height: 100%
}

.main-screen-editor-inner {
    background-color: white;
    padding: 0.25rem;
    flex-grow: 1;
}

.width-66 {
    width: 66.7%;
}

.width-100 {
    width: 100%;
}

.preview-section {
   margin: 0.25rem 0.25rem 0.25rem 1rem;
   border: solid 0.5rem #e9ecef;
   width: 33.3%;
   min-height: 640px;
   min-width: 392px;
   overflow-x: hidden;
   position: relative;
   padding: 0;
   font-family: -apple-system, "Helvetica Neue", Roboto, "Segoe UI", sans-serif;
}

.preview-section::before {
    content: "";
    display: block;
    padding-top: 178%;
}

.preview-content{
    position:  absolute;
    display: inline-block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-width: 360px;
}

.preview-swicther-line {
    margin: 0.5rem 0.25rem;
    padding: 0rem 1rem;
    align-items: center;
}

.justify-content-flex-start {
    justify-content: flex-start !important;
}

.justify-content-space-berween {
  justify-content: space-between;
}

.save-cancel-block {
    background-color: white;
    padding: 0.5rem 0.25rem;
    margin: 0.125rem 0rem;
    border: none;
}

.save-cancel-button {
    height: 2.5rem;
}
