.file-upload {
  display: flex;

  .file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    & + label {
      font-size: 16px;
      margin: 4px;
      border-radius: 4px;
      background-color: #ffc20e;
      border: 1px solid #ffc20e;
      cursor: pointer;
      padding: 6px 12px;
      &:hover {
        background-color: #e7ac00;
        border-color: #daa300;
      }
    }
    &:disabled + label {
      opacity: 0.5;
      cursor: default;
      &:hover {
        background-color: #ffc20e;
        border-color: #daa300;
      }
    }
  }

  label + label {
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 6px 12px;
    margin: 4px;
  }

  .fa-times {
    top: 37px;
  }
}