@import "../../../variables.scss";

.cursor-pointer {
  cursor: pointer;
}

.amr-menu, .amr-status {
  &.edit-mode-active {
    margin-bottom: $saveCancelBlockHeight;
  }
  
  .svg-inline--fa.selected {
    color: #ffc20e;
  }

  .fa-plus {
    right: 0;
    font-size: 1rem;
  }
}

.amr-status.form-list {
  background: #e2e3e5;
}

.amr-status {
  .fa-times {
    font-size: 15px;
    right: 25px;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }

  .tree-view {
    max-height: 700px;
    ul {
      margin-left: 5px;
      padding: 0;
      list-style: none;
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 1px solid;
      }
      li {
        margin: 0;
        padding: 0 1em;
        line-height: 2em;
        font-weight: 700;
        position: relative;
        &:before {
          content: "";
          display: block;
          width: 10px;
          height: 0;
          border-top: 1px solid;
          margin-top: -1px;
          position: absolute;
          top: 1em;
          left: 0;
        }
        &:last-child:before {
          background: #fff;
          height: auto;
          top: 1em;
          bottom: 0;
        }
      }
    }
    & > ul {
      margin: 0;
      &:before {
        content: none;
      }
      &>li:before {
        content: none;
      }
    }
  }
}

.grey-bar {
  height: 2.75rem;
  background-color: #e9ecef;
}