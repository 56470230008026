.tree-view {
  ul {
    list-style-type: none;
    padding-left: 15px;

    .toggle-button {
      cursor: pointer;
    }

    .field-delete {
      display: none;
    }

    .field-block:hover {
      cursor: pointer;
      font-weight: bold;

      .field-delete {
        display: inline;
      }
    }
  }
}
