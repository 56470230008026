@import "../../../../variables.scss";

.MultiSelect {
  button {
    background: $grey-3;
    padding-top: 0.24rem;
    padding-bottom: 0.24rem;
    color: $grey-8;
    font-size: 0.875rem;

    &:not(:disabled) {
      color: $grey-7;

      &.multiItem span {
        background: $yellow;

        &.checked:after {
          content: "\2713";
        }
        &.unchecked {
          border: 1px solid $grey-4;
          background: transparent;
        }
      }
    }

    &.multiItem span {
      line-height: 1.2rem;
      height: 1.2rem;
      width: 1.2rem !important;
      font-size: 0.7rem;
      margin-right: 0.7rem;
      color: white;
      background: $grey-8;
      border-radius: 2px;

      &.checked:after {
        content: "\2713";
      }
      &.unchecked {
        border: solid 1px $grey-8;
        background: transparent;
      }
    }
  }
}
