@import "../../app/App";
@import "../../../variables.scss";

.save-cancel-block {
  border-top: 1px solid lightgrey;
  left: $sidebarWidthMobile !important;
  height: $saveCancelBlockHeight;

  @media (min-width: 576px) {
    left: $sidebarWidth !important;
  }
}

.edit-integrations-settings-block {
  margin-bottom: $saveCancelBlockHeight;
}
