.alert {
    p:last-of-type {
        margin: 0;
    }
}
.modal-title {
    .fileName {
        font-size: 16px;
        margin-bottom: 0;
    }
}
.errorsTable td, .errorsTable th {
    text-align: center;   
}
.importResultsContainer {
     max-height: 50vh;
     overflow-x: auto;
}