@import "../app/App";
.optionSelector {
  .topLabel {
    height: $optionSelectorTopLabelHeigth;
  }
  .optionButtonRow {
    height: $optionSelectorButtonRowHeight;
  }
  .optionSelectorMenu {
    height: $optionSelectorMenuHeight;
    position: absolute;
    top: $optionSelectorButtonRowHeight + $optionSelectorTopLabelHeigth;
    z-index: 1;
    left: 0;
    width: 50%;
    right: 0;

    .optionSelectorSearch {
      height: $optionSelectorSearchHeight;
    }
    .optionList {
      height: $optionSelectorMenuHeight - $optionSelectorSearchHeight - $spacer -$spacer *
        0.25;

      .optionItem.active {
        background-color: $blue;
        color: white;
      }
    }
  }
}
